import React, { useEffect } from "react"
import './headband.css';



const HeadbandTrabalheConosco = () => {


  useEffect(() => {
  }, [])

  return (

    <>


      <div id="headband">
        <div className="container">
          <h1>Trabalhe Conosco</h1>
          <div className="box-p">
            
          </div>


        </div>
      </div>
    </>
  )
}

export default HeadbandTrabalheConosco


