import React, { useEffect } from "react"
import './headbandBlack.css';



const HeadbandBlack = () => {


  useEffect(() => {
  }, [])

  return (

    <>


      <div id="headbandBlack">
        <div className="container">
          <center><h1><span>BLACK FRIDAY</span><br/>TC DO BRASIL</h1></center>
        {/*   <div className="box-pBlack">
            <p>
            "Otimize o desempenho e a disponibilidade de suas aplicações com nossos serviços de hospedagem especializados. Conte com nossa expertise para garantir que suas aplicações estejam sempre no ar e funcionando com eficiência."
            </p>
          </div> */}


        </div>
      </div>
    </>
  )
}

export default HeadbandBlack


