import React, { useEffect } from "react"
import './headband.css';



const HeadbandSites = () => {


  useEffect(() => {
  }, [])

  return (

    <>


      <div id="headband">
        <div className="container">
          <h1>Hospedagem de Sites</h1>
          <div className="box-p">
            <p>
            "Hospedar seu site com a TC do Brasil é uma decisão estratégica que coloca a qualidade e a confiabilidade no centro da sua presença online."
            </p>
          </div>


        </div>
      </div>
    </>
  )
}

export default HeadbandSites


