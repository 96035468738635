import React, { useEffect } from "react"
import './headband.css';



const HeadbandPolitica = () => {


  useEffect(() => {
  }, [])

  return (

    <>

   
      <div id="headband">
        <div className="container">
          <h1>Politicas de privacidade e Termos de uso</h1>
          


        </div>
      </div>
    </>
  )
}

export default HeadbandPolitica