import React, { useEffect } from "react"
import './headband.css';



const HeadbandParceiros = () => {


  useEffect(() => {
  }, [])

  return (

    <>

   
      <div id="headband">
        <div className="container">
          <h1>Nossos parceiros</h1>
          <div className="box-p">
            <p>
              "Para oferecer as melhores soluções aos nossos clientes, contamos com parceiros fabricantes de softwares e hardware de abrangência global"
            </p>
          </div>


        </div>
      </div>
    </>
  )
}

export default HeadbandParceiros


