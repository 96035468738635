import React, { useEffect } from "react"
import './headband.css';



const HeadbandLinkDedicado = () => {


  useEffect(() => {
  }, [])

  return (

    <>


      <div id="headband">
        <div className="container">
          <h1>Links Dedicados</h1>
          <div className="box-p">
            <p>
            "Esse serviço oferece uma conexão à internet totalmente dedicada e garantida, resultando na mais elevada qualidade de acesso disponível para as empresas."
            </p>
          </div>


        </div>
      </div>
    </>
  )
}

export default HeadbandLinkDedicado


